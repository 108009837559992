@import 'styles/all_vars';

$transition-duration: 0.3s;

.modal {
  display: none;
  position: fixed;

  .modal-background {
    z-index: 10;
  }

  .notification {
    max-width: 100%;
    //min-width: 100%;
    width: 90%;
    margin: 0 2rem;
    padding: 2.125rem 1rem;
    background: $white;
    border-radius: $radius-large;
    text-align: center;
    z-index: 20;

    @include tablet() {
      min-width: 320px;
      width: auto;
      padding: 2.125rem;
    }

    .delete {
      background-color: $red;
      max-width: 40px;
      max-height: 40px;
      width: 40px;
      height: 40px;
      position: absolute;
      right: -12px;
      top: -12px;
      z-index: 1;
    }

    .title {
    }

    .subtitle {
      color: $grey-dark;
      margin-bottom: 1.125rem;
    }

    .buttons {
      margin-top: 1.125rem;
    }
  }
}

#confirm-modal {
  .buttons {
    flex-flow: row;

    .button {
      width: 50%;
      min-width: 8rem;
    }
  }
}
